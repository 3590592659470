var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450px" },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", [_vm._v(_vm._s(_vm.fileItem.name))]),
              ]),
              _vm.fileItem.description
                ? _c("v-card-text", [
                    _vm._v(" Observação: "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.fileItem.description) + " "),
                  ])
                : _c("v-card-text", [
                    _vm._v(" Observação: "),
                    _c("br"),
                    _vm._v(" Não há informações a serem exibidas "),
                  ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { height: "48px" },
                      attrs: {
                        ripple: false,
                        color: "success",
                        elevation: 0,
                        id: "btnClose",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeDetails()
                        },
                      },
                    },
                    [_vm._v(" Fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }