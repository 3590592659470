<template>
  <div class="d-flex flex-column" style="padding-top: 120px !important">
    <v-breadcrumbs
      :items="items"
      style="
        padding-left: 5px !important;
        max-height: 36px !important;
        flex-direction: row;
        display: flex;
        padding: 0px !important;
        margin: 0px !important;
      "
      id="breadFilesHeader"
    >
      <template v-slot:divider>
        <span
          class="material-icons-outlined"
          id="arrowId"
          style="
            display: flex;
            width: 16px;
            height: 16px;
            padding: 4px 5.53px;
            justify-content: center;
            align-items: center;
            color: #8d8d99 !important;
            pointer-events: none !important;
          "
        >
          keyboard_arrow_right
        </span>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item icon v-if="item.title === 'Home'" :href="'/home'" :disabled="item.disabled">
          <v-btn fab x-large style="width: 32px; height: 32px" id="homeButton" class="shadow-none" :ripple="false">
            <span class="material-icons-outlined shadow-none textColor--text"> home </span>
          </v-btn>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" v-else>
          <span class="breadTittle" :id="item.title"> {{ item.title }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row class="my-0 pt-8 pb-8">
      <v-col cols="8" class="py-0">
        <h2 class="titleColor--text" :style="'font-size: 32px!important; font-weight: bold!important'">Arquivos</h2>
        <p id="subtitleForFilesHeader">Gerenciamento de arquivos</p></v-col
      >
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'HeaderFiles',
  components: {
    // HistoryModal: () => import('./HistoryModal.vue'),
  },

  data: () => ({
    items: [
      {
        title: 'Home',
        disabled: false,
        href: 'home',
      },
      {
        title: 'Documentos',
        disabled: true,
      },
      {
        title: 'Arquivos',
        disabled: true,
        href: 'Arquivos',
      },
    ],
  }),
  computed: {},
  methods: {},
};
</script>
<style scoped>
.material-icons-outlined:not('arrowId') {
  width: 16px;
  height: 16px;
  color: #2d5796 !important;
  font-weight: 400 !important;
}
.material-icons-outlined {
  font-size: 16px;
  font-weight: 400 !important;
}
.material-icons-outlined:hover:not(.iconRestart) {
  color: #2d5796 !important;
  font-weight: 400 !important;
  background-color: #e3ffee;
}
.iconRestart-disabled {
  color: #8686a2 !important;
  font-weight: 400 !important;
}
.v-btn--disabled {
  cursor: default;
}
.material-icons-outlined:active {
  color: var(--v-primary-base);
  font-weight: 400 !important;
  background-color: #00ff99;
}
::v-deep .v-breadcrumbs__divider {
  margin-left: 8px !important;
  width: 16px !important;
  height: 16px !important;
  padding: 0px !important;
  align-items: start;
}
.colBreadcrumb {
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
  max-height: 36px !important;
}
::v-deep #subtitleForFilesHeader {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: 400 !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}

::v-deep .breadTittle {
  padding: 8px 16px !important;
  color: #8d8d99 !important;
  font-weight: 700 !important;
}
::v-deep .breadTittle#Colaboradores {
  font-weight: 700 !important;
}

::v-deep .v-btn--text:before {
  background-color: transparent !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1;
  color: #2d5796;
}
::v-deep .v-btn--text:after {
  background-color: transparent !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1;
  color: #2d5796;
}

::v-deep .v-btn__content:hover .textChange {
  color: #3c73c7 !important;
  text-decoration: none !important;
  background-color: none !important;
}
::v-deep .v-btn__content .textChange-disabled {
  color: #8686a2 !important;
  text-decoration: none !important;
  background-color: none !important;
}
::v-deep .v-btn__content:hover .material-icons-outlined {
  color: #3c73c7 !important;
}

::v-deep #homeButton .v-btn__content .material-icons-outlined:active {
  color: #ffffff !important;
  background-color: #2a1c4f !important;
}
::v-deep #homeButton {
  padding: 4px !important;
  border-radius: 28px !important;
  background-color: transparent;
  box-shadow: none !important;
}
::v-deep #homeButton .v-btn__content {
  height: 24px !important;
}

::v-deep #homeButton .v-btn__content .material-icons-outlined {
  padding-top: 2px;
  padding-right: 3px;
  height: 20px !important;
  width: 17px !important;
}
::v-deep #homeButton:hover .material-icons-outlined {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
  outline: none !important;
}

::v-deep #homeButton:hover {
  border-radius: 28px !important;
  background-color: #e3ffee;
  box-shadow: none !important;
  outline: none !important;
}
</style>
