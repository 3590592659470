var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-card", { attrs: { id: "tableFiles" } }, [
        _c(
          "div",
          { attrs: { id: "arquivos-container" } },
          [
            _c(
              "v-data-table",
              {
                attrs: {
                  id: "tableFiles",
                  loading: _vm.loadingTable,
                  headers: _vm.headers,
                  options: _vm.pagination,
                  items: _vm.arquivos,
                  page: _vm.pagination.page,
                  "server-items-length": _vm.totalArquivos,
                  "footer-props": {
                    disablePagination:
                      _vm.companiesList.length === 0 || _vm.loadingTable,
                    itemsPerPageOptions:
                      _vm.totalArquivos < 50
                        ? [
                            { value: 50, text: "50" },
                            { value: 100, text: "100", disabled: true },
                            { value: 250, text: "250", disabled: true },
                          ]
                        : _vm.totalArquivos < 100
                        ? [
                            { value: 50, text: "50" },
                            { value: 100, text: "100" },
                            { value: 250, text: "250", disabled: true },
                          ]
                        : [
                            { value: 50, text: "50" },
                            { value: 100, text: "100" },
                            { value: 250, text: "250" },
                          ],
                    disableItemsPerPage:
                      _vm.arquivos.length === 0 || _vm.loadingTable,
                    itemsPerPageText: "Arquivos por página:",
                    expandIcon: "mdi-dots-horizontal",
                  },
                  "no-data-text": `Não há registros de arquivos a serem exibidos`,
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.uploadedAt",
                    fn: function ({ item }) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("datetime")(item.uploadedAt, {
                                mask: "DD/MM/YYYY",
                                showEmpty: true,
                              })
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _vm._v(
                          " Não há registros de arquivos a serem exibidos "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "AGTooltip",
                          { attrs: { "tooltip-text": "Download" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2 button-size",
                                attrs: {
                                  icon: "",
                                  color: "primary",
                                  id: "downloadBtn",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "material-icons-outlined" },
                                  [_vm._v("download")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.description",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2 button-size",
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openDetails(item)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "material-symbols-outlined" },
                              [_vm._v(" description ")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer.page-text",
                    fn: function (items) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                            " - " +
                            _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                            " de " +
                            _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                            " arquivos "
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm.loadingTable
                  ? _c("template", { slot: "body" }, [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function (loadItem) {
                          return _c(
                            "td",
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "loaderCss",
                                attrs: { "max-width": loadItem.width },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("FileDetailsModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }