<template ref="tableArquivos">
  <div>
    <v-card id="tableFiles">
      <div id="arquivos-container">
        <v-data-table
          id="tableFiles"
          :loading="loadingTable"
          :headers="headers"
          :options.sync="pagination"
          :items="arquivos"
          :page="pagination.page"
          :server-items-length="totalArquivos"
          :footer-props="{
            disablePagination: companiesList.length === 0 || loadingTable,
            itemsPerPageOptions:
              totalArquivos < 50
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100', disabled: true },
                    { value: 250, text: '250', disabled: true },
                  ]
                : totalArquivos < 100
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250', disabled: true },
                  ]
                : [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250' },
                  ],
            disableItemsPerPage: arquivos.length === 0 || loadingTable,
            itemsPerPageText: 'Arquivos por página:',
            expandIcon: 'mdi-dots-horizontal',
          }"
          :no-data-text="`Não há registros de arquivos a serem exibidos`"
        >
          <template v-slot:item.uploadedAt="{ item }">
            <div>{{ item.uploadedAt | datetime({ mask: 'DD/MM/YYYY', showEmpty: true }) }}</div>
          </template>
          <template v-slot:no-data> Não há registros de arquivos a serem exibidos </template>
          <template v-slot:item.actions="{ item }">
            <AGTooltip tooltip-text="Download">
              <v-btn icon color="primary" class="mr-2 button-size" @click="downloadFile(item)" id="downloadBtn">
                <span class="material-icons-outlined">download</span>
              </v-btn>
            </AGTooltip>
          </template>
          <template v-slot:item.description="{ item }">
            <v-btn icon class="mr-2 button-size" @click="openDetails(item)">
              <span class="material-symbols-outlined"> description </span>
            </v-btn>
          </template>
          <template v-slot:footer.page-text="items">
            {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
            {{ loadingTable ? 0 : items.itemsLength }} arquivos
          </template>
          <template slot="body" v-if="loadingTable">
            <tr>
              <td v-for="loadItem in headers">
                <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <FileDetailsModal />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FileDetailsModal from './DetailsModal.vue';
import GedService from '@/services/gedService.js';
import { eventBus } from '@/main.js';

export default {
  name: 'TableFiles',

  components: {
    FileDetailsModal,
    AGTooltip: () => import('../../../commons/AGTooltip.vue'),
  },
  updated() {
    this.customize();
    this.changeIcon();
  },

  mounted() {
    this.changeIcon2();
  },
  watch: {},
  data() {
    return {
      up: false,
      dialogBool: false,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      headers: [
        { text: 'Nome', value: 'fileName', width: '200px', sortable: false },
        { text: 'Data de publicação', value: 'uploadedAt', width: '140px', sortable: false },
        { text: 'Observação', value: 'description', width: '150px', sortable: false },
        { text: 'Ações', value: 'actions', width: '78px', sortable: false },
      ],
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },
  created() {},

  computed: {
    ...mapGetters({
      companiesList: 'documents/getCompaniesList',
      arquivos: 'documents/getFilesList',
      loadingTable: 'documents/getLoadingStatus',
      paginationFiles: 'documents/getPaginationFiles',
      totalArquivos: 'documents/getTotalArquivos',
    }),

    pagination: {
      get: function () {
        return this.paginationFiles;
      },
      set: function (value) {
        this.setPaginationFiles(value);
      },
    },
    isDisabled() {
      if (this.arquivos.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.loadingTable) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    changeIcon2() {
      const listItem = document.getElementsByClassName('material-icons-outlined');

      let selected = '';
      if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
        selected = listItem[4];
        selected.style.color = 'primary';
        selected.style.fontSize = '24px';
        selected.style.fontWeight = 'regular';

      } else {
        const listItemNew = document.getElementsByClassName('v-input__icon v-input__icon--append')[0];
        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        listItemNew.parentNode.replaceChild(newITem, listItemNew);
      }
    },
    openDetails(details) {
      this.openFileModal(details);
    },

    changeIcon() {
      const isDisabledOrNot = document.getElementsByClassName(
        'v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-select',
      );

      if (isDisabledOrNot[0]) {
        const listItem = document.getElementsByClassName(
          'v-icon notranslate v-icon--disabled mdi mdi-menu-down theme--light',
        );

        const itemSelected = listItem[0];

        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        if (listItem[0]) {
          listItem[0].parentNode.replaceChild(newITem, itemSelected);
        }
      } else {
        const listItem = document.getElementsByClassName('material-icons-outlined');

        let selected = '';
        if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
          //<!-- const itemSelected = listItem[4]; -->
          selected = listItem[4];
          selected.style.color = 'primary';
          selected.style.fontSize = '24px';
          selected.style.fontWeight = 'regular';
        }
      }
    },

    ...mapActions('documents', ['openFileModal', 'init', 'setPaginationFiles']),

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },

    async downloadFile(item) {
      let file = await GedService.files.get(item.gedId);
      const linkSource = 'data:application/octet-stream;base64,' + file.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = item.fileName;
      downloadLink.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },
    customize() {
      let tableLines = document.querySelectorAll('#arquivos-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
  },
};
</script>
<style scoped>
::v-deep .v-da #tableFiles {
  border: 1px solid #e7e7fa !important;
}
#arquivos-container {
  height: auto;
}
ta-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
  white-space: nowrap;
}
::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  white-space: nowrap;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-start {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #52526b !important;
}
::v-deep .v-data-table tr.v-data-table__empty-wrapper {
  background-color: #f7f7fd !important;
  color: #52526b !important;
}
::v-deep .v-label {
  color: var(--v-primary-base);
}

:v-deep v-btn .v-btn--disabled .v-btn--icon .v-btn--round .v-btn--text .theme--light .v-size--default {
  margin-right: 16px !important;
}
::v-deep .v-label--active {
  color: #575767 !important;
}

::v-deep .icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}

::v-deep .v-icon-clicked {
  color: var(--v-primary-base);
  font-size: 16px !important;
  background-color: #f7f7fd !important;
  border-radius: 100% !important;
  border-color: #e7e7fa !important;
}

::v-deep .v-input__slot::before {
  border: none !important;
}

::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}

.enabled-field {
  max-width: 586px;
  max-height: 48px;
  background-color: #ffffff;
  padding: 12px 0px;
}

::v-deep .v-input__icon.v-input__icon--append {
  margin-top: 8px !important;
}
::v-deep .enabled-field .v-input__slot::before {
  border: 1px solid #8686a2 !important;
}
::v-deep .enabled-field .v-label {
  color: #52526b !important;
}
.disabled-field {
  max-width: 586px;
  max-height: 48px;
  background-color: #e7e7fa !important;
  padding: 12px 0px;
  border-radius: 4px;
}
::v-deep .disabled-field .v-label {
  color: #52526b !important;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
}
::v-deep .disabled-field .v-input__icon .v-input__icon--append {
  padding: 12px 16px !important;
  margin: 8px !important;
}
.loaderCss {
  width: 100%;
  margin-top: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #ece7f5;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  animation: shine 2s ease infinite;
}
::v-deep .v-data-table__empty-wrapper td {
  text-align: start !important;
  padding-left: 600px !important;
}

::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-input__slot::before {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control:hover {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  transform: scaleX(0) !important;
}

::v-deep div.v-input--is-label-active:nth-child(1) > div:nth-child(1) {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-data-footer__select .v-select {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-application--wrap .v-menu__content .theme--light .menuable__content__active {
  margin-top: 50px !important;
}
::v-deep .v-select__selection--comma {
  overflow: visible !important;
}
::v-deep .v-select__selection.v-select__selection--comma {
  overflow: visible !important;
}
::v-deep #tablec .v-select__selection {
  color: #8686a2 !important;
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection {
  color: var(--v-primary-base);
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection:hover {
  background-color: #e3ffee !important;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}
::v-deep .v-input__slot {
  height: 48px !important;
}

::v-deep .v-input {
  align-items: center !important;
}
::v-deep .v-list-item--link::before {
  background-color: none;
}

::v-deep .v-data-footer .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}
::v-deep .v-list-item:hover.v-list-item--link.theme--light {
  background-color: #e3ffee !important;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  padding: 0 !important;
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  padding: 0 !important;
  border-radius: 100% !important;
}
@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
