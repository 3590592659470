<template>
  <v-row justify="center">
    <v-dialog v-model="showModal" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span>{{ fileItem.name }}</span>
        </v-card-title>

        <v-card-text v-if="fileItem.description">
          Observação: <br />
          {{ fileItem.description }}
        </v-card-text>
        <v-card-text v-else>
          Observação: <br />
          Não há informações a serem exibidas
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            color="success"
            :elevation="0"
            id="btnClose"
            @click="closeDetails()"
            style="height: 48px"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FileDetailsModal',
  components: {},

  data() {
    return {
      clicked: false,
      historics: [
        { id: 1, nome: 'exemplo1', hora: '05/09/2022 - 09:20' },
        { id: 2, nome: 'exemplo2', hora: '05/09/2022 - 09:20' },
        { id: 23, nome: 'exemplo13', hora: '05/09/2022 - 09:20' },
        { id: 23, nome: 'exemplo13', hora: '05/09/2022 - 09:20' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      showModal: 'documents/getShowModal',
      fileItem: 'documents/getFileModalItem',
      blobHistoryImport: 'wss/getBlobHistoryImport',
    }),
    show() {
      return this.showModal;
    },
  },

  async created() {},
  methods: {
    ...mapActions('documents', ['closeFileModal']),

    closeDetails() {
      this.closeFileModal(false);
    },
  },
};
</script>
<style scoped></style>
