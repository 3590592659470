var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [_c("HeaderFiles", { ref: "headerWss" }), _c("TableFiles")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }