<template>
  <v-main>
    <HeaderFiles ref="headerWss" />
    <TableFiles />
  </v-main>
</template>

<script>
import HeaderFiles from './components/HeaderFiles.vue';
import TableFiles from './components/TableFiles.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Wss',
  components: { HeaderFiles, TableFiles },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  async created() {
    this.initFile(this.userInfo.id);
  },
  methods: {
    ...mapActions('documents', ['initFile']),
  },
};
</script>
<style scoped></style>
